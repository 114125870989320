@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  font-family: 'Inter', sans-serif !important;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 7vh;
}

.MainLeftColumn {
  margin-top: 10px;
  justify-content: center;
  margin-left: 35px;
}

.MainLeftColumn p {
  width: 100%;
  font-size: 1rem;
}

.ProjectsContainer {
  margin-top: 70px !important;
  width: 80% !important;
  margin-left: auto !important; 
  margin-right: auto !important;
}

.SkillsContainer {
  margin-top: 100px !important;
  margin-bottom: 70px !important;
  width: 100% !important;
}

.BlogContainer {
  width: 60% !important;
  justify-content: center;
  margin: auto; 
  font-size: 15px; 
  text-indent: 20px; 
  margin-bottom: 20px;
}

.ProjectBlogImage {
  width: 100%;
  display: flex;
  margin: auto;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.dividingRowProjects {
  margin-top: 20px !important;
}

.upperContainerHome {
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .upperContainerHome {
    /* padding-left: 100px !important;
    padding-right: 100px !important; */
    width: 85%;
  }
}

.MainRightColumn img {
  width: 70% !important;
}

.SkillsList li {
    display: inline-table;
    width: auto;
    font-size: 18px;
    padding: 10px;
    margin: 5px;
    margin-bottom: 5px;
    text-align: center;
    background-color: #0C6C4A;
    color: white;
    cursor: pointer;
    border-radius: 8px;
}

.skillLogo {
  margin: 10px;
}

.projectImage {
  width: 90%;
  border: #dbdbdb 1px solid;
  margin: auto;
  justify-content: center;
  display: flex;
  margin-top: 5px;
  margin-bottom: 3px;
}

.projectCardLarge {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  position: relative;
  border: 1px black;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  padding-top: 40px;
  padding-bottom: 40px;
}

.projectCardLarge img {
  width: 450px;
}

.projectCard {
  position: relative;
  border: 1px black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  margin: 2px;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  width: 100%; 
  height: 375px; 
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}

/* .projectCard:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.45);
  transform: scale(1.005);
} */

.projectCardDesc {
  position: relative;
  width: 80%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  text-align: start;
  align-self: center;
  font-size: 1rem;
}

.projectCardTitle {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  font-size: 1.35rem;
}

.projectCardButtons {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  z-index: 2;
  pointer-events: auto;
}

.projectAnchor {
  color: inherit;
}

.projectAnchor:hover {
  color: inherit;
}

.greenButton {
  background-color: #0E8A5F !important;
  color: white !important;
}

.greenButton:hover {
  background-color: #0c7951 !important;
}

.carouselContainer {
  padding-bottom: 35px;
}

#root > div > div:nth-child(2) > div > div > button.slick-arrow {
  color: black;
}

.slick-prev:before {
  color: grey !important;
  font-size: 32px !important;
}

.slick-next:before {
  color: grey !important;
  font-size: 32px !important;
}

.projectCardLargeImgContainer {
  margin-right: 30px;
}

.MainRightColumn {
  display: flex;
  justify-content: center;
}

/* Phones  */
@media (max-width: 767px) {

  .contactBtnsContainer {
    display: flex;
    justify-content: center;
  }

  .projectCardLarge h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  .projectCardLarge p {
    margin-left: 15px;
    width: 95%;
  }

  .projectCardLargeImgContainer {
    margin-right: 0px;
  }

  .projectCardLarge {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  }

  .projectCardLarge img {
    width: 100%;
    margin-bottom: 25px;
  }

  .MainLeftColumn {
    margin-top: 5%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .MainLeftColumn h2, h3 {
    text-align: center;
  }

  .MainLeftColumn p {
    text-align: center;
    font-size: 1rem !important;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
  }
  .FollowSocials {
    justify-content: center !important;
  }
  .ProjectsContainer {
    margin-top: 50px !important;
    width: unset !important;
  }
  .projectCard {
    margin: 5px !important;
  }
  .MainRightColumn {
    margin-bottom: 50px !important;
    display: flex;
    justify-content: center;
  }
  .SkillsContainer {
    margin-top: 60px !important;
    margin-bottom: 70px !important;
    width: '50%' !important;
  }
  .BackLinkNavBar {
    margin-right: 20px !important; 
  }
  .BlogContainer {
    margin-top: 10px !important;
    width: 95% !important;
  }
  .dividingRowProjects {
    margin-top: 0px !important;
    margin: auto !important;
  }
  .rowOneProjects {
    margin: auto !important;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1000px) {

  .MainLeftColumn {
    margin-top: 40px;
  }

  .projectCardLarge {
    flex-direction: column;
  }

  .projectCardLarge h3 {
    text-align: center;
    margin-top: 20px !important;
  }

  .projectCardLargeTextContainer {
    margin: auto;
    width: 75%;
  }
}

